import { DrupalNode } from "next-drupal";

interface FormTargetModel {
  servizi: string;
}

export function setFormTarget(node: DrupalNode): void {
  if (node?.field_form_servizi) {

    const formData: FormTargetModel = {
      servizi: node?.field_form_servizi || ""
    };

    window.localStorage.setItem("formdata", JSON.stringify(formData));
  }
}

export function getFormTarget(): FormTargetModel | null {
  const formData = localStorage.getItem("formdata");
  return formData ? JSON.parse(formData) as FormTargetModel : null;
}

export function removeFormTarget() {
  window.localStorage.removeItem("formdata");
}

export function setReferrerPage() {
  const currentPage = window.location.href;
  window.localStorage.setItem("referrerPage", currentPage);
}

export function getReferrerPage() {
  return localStorage.getItem("referrerPage");
}

export const formTargetServiziMap = {
  "Altri servizi ambientali": "Altri servizi ambientali",
  "Biometano": "BIOGAS E BIOMETANO",
  "Bonifiche ambientali": "Bonifiche ambientali",
  "Campionamento e monitoraggio": "Campionamento e monitoraggio",
  "Cogenerazione e trigenerazione": "Cogenerazione",
  "Comunità Energetiche Rinnovabili": "Energy communities",
  "Consulenze ambientali": "CONSULENZE IN AMBITO GESTIONE AMBIENTALE",
  "Diagnosi e consulenze energetiche": "Diagnosi e consulenze energetiche",
  "Elettrificazione Flotte": "Elettrificazione Flotte",
  "Fotovoltaico e batterie": "FOTOVOLTAICO E STORAGE",
  "Gestione e valorizzazione dei rifiuti": "GESTIONE E SMALTIMENTO DEI RIFIUTI INDUSTRIALI",
  "Idrogeno": "Idrogeno",
  "Illuminazione pubblica intelligente": "ILLUMINAZIONE PUBBLICA",
  "Mobilità Elettrica": "Mobilità Elettrica",
  "Net zero": "Net zero",
  "Riqualificazione edifici e spazi pubblici": "RIQUALIFICAZIONE SPAZI PUBBLICI",
  "Servizi Digitali": "Servizi Digitali",
  "Servizi digitali per le città": "Servizi digitali per le città",
  "Soluzioni smart per le città": "Soluzioni smart per le città",
  "Teleriscaldamento": "Teleriscaldamento",
  "Trattamento acque": "TRATTAMENTO ACQUE PRIMARIE E REFLUE",
  "Utilities energetiche": "Utilities energetiche"
}