
import { validatorTypes, componentTypes } from '@data-driven-forms/react-form-renderer';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import { i18n } from 'next-i18next'
import { jobsValues } from './options/jobs';
import { labelProps, defaultInputProps } from './defaultProps';

i18n?.init();

const areeInteresseValues = [
  { label: 'Sustainable self-generation', value: 'AUTOPRODUZIONE SOSTENIBILE' },
  { label: 'Energy and environmental advisory', value: 'CONSULENZA ENERGETICA' },
  { label: 'Circular economy', value: 'SERVIZI AMBIENTALI' },
  { label: 'Energy efficiency', value: 'RIQUALIFICAZIONE ENERGETICA' },
  { label: 'Green Gas Energy', value: 'Green Gas' },
  { label: 'Sustainable mobility', value: "MOBILITA' SOSTENIBILE" },
  { label: 'Smart city and urban renewal', value: 'SMART CITIES E RIGENERAZIONE URBANA' },
]

// Options
export const validationMessages = {
  standard: "Incorrect format",
  required: "Required field",
  phone: {
    min: "Minimum 8 characters",
    max: "Maximum 15 characters",
  },
  cap: "The value is not a valid postal code",
  piva: {
    length: "The field must be 11 characters long"
  },
  email: {
    blocklist: "Business emails only"
  }
}

/* Global Fields 
--------------------------- */

// First step


export const areaInteresse = {
  component: componentTypes.SELECT,
  name: 'tipologia_richiesta__c',
  label: 'Main area of interest',
  placeholder: "Choose an area of interest",
  isRequired: true,
  FormFieldGridProps: { lg: 6 },
  TextFieldProps: { variant: "standard", InputLabelProps: {...labelProps} },
  simpleValue: true,
  ...defaultInputProps,
  options: areeInteresseValues,
  validate: [{ type: validatorTypes.REQUIRED, message: 'campo richiesto' }],
  isOptionEqualToValue: (option, value) => option.value === value
};

const filteredOptions = (value) => {
  const curOptionIndex = areeInteresseValues.findIndex(element => element.value === value);
  const ulterioriAreeInteresseValues = [...areeInteresseValues];
  ulterioriAreeInteresseValues.splice(curOptionIndex, 1);
  
  if(value) {
    return Promise.resolve([...ulterioriAreeInteresseValues]);
  } else {
    return Promise.resolve([]);
  }
};

const filteredOptionsMapper = (value) => {
  return areeInteresseValues.findIndex(element => element.value === value);
};

export const ulterioriAreeInteresse = {
  component: componentTypes.SELECT,
  name: 'ulteriori_aree_d_interesse__c',
  label: 'Other areas of Interest (optional)',
  placeholder: "Choose other areas of interest",
  FormFieldGridProps: { lg: 6 },
  TextFieldProps: { variant: "standard", InputLabelProps: {...labelProps} },
  ...defaultInputProps,
  isRequired: false,
  isMulti: true,
  simpleValue: true,
  options: areeInteresseValues,
  closeMenuOnSelect: false,
  disableCloseOnSelect: true,
  componentsProps: {
    paper: { className: 'contactForm-Paper', }
  },
  isOptionEqualToValue: (option, value) => option.value === value,
  renderOption: (props, option, state) => {
    return (
      <MenuItem {...props}>
        <Checkbox checked={state?.selected} />
        <ListItemText primary={option?.label}/>
      </MenuItem>
    )
  },
  onInputChange: () => null,
  noOptionsMessage: 'Choose an area of interest',
  resolveProps: (_props, _field, { getState }) => {
    const areaDiInteressePrincipaleValue = getState().values.tipologia_richiesta__c;
    return {
      loadOptionsChangeCounter: filteredOptionsMapper(areaDiInteressePrincipaleValue),
      loadOptions: () => filteredOptions(areaDiInteressePrincipaleValue)
    };
  },
};

const filteredServizioOptions = (value) => {

  switch (value) {
    case 'AUTOPRODUZIONE SOSTENIBILE':
      return Promise.resolve([
        {label: 'Cogeneration and trigeneration', value: 'Cogenerazione'},
        {label: 'Photovoltaic and batteries', value: 'FOTOVOLTAICO E STORAGE'}
      ]);
      break;
    
    case 'Green Gas':
      return Promise.resolve([
        {label: 'Hydrogen', value: 'IDROGENO'},
        {label: 'Biometano', value: 'BIOGAS E BIOMETANO'}
      ]);
      break;
      
    case 'RIQUALIFICAZIONE ENERGETICA':
      return Promise.resolve([
        {label: 'Energy utilities', value: 'Utilities energetiche'},
        {label: 'Digital services', value: 'Servizi Digitali'}
      ]);
      break;

    case 'SMART CITIES E RIGENERAZIONE URBANA':
      return Promise.resolve([
        {label: 'Redevelopment of buildings and public spaces', value: 'RIQUALIFICAZIONE SPAZI PUBBLICI'},
        {label: 'District heating', value: 'Teleriscaldamento'},
        {label: 'Intelligent public lighting', value: 'ILLUMINAZIONE PUBBLICA'},
        {label: 'Smart solutions for cities', value: 'Soluzioni smart per le città'},
        {label: 'Digital services for cities', value: 'Servizi digitali per le città'},
        {label: 'Energy communities', value: 'Energy communities'}
      ]);
      break;

    case "MOBILITA' SOSTENIBILE":
      return Promise.resolve([
        {label: 'Fleet electrification', value: 'Elettrificazione Flotte'},
        {label: 'Electric Mobility', value: 'Mobilità Elettrica'}
      ]);
      break;

    case 'SERVIZI AMBIENTALI':
      return Promise.resolve([
        {label: 'Waste management and valorisation', value: 'GESTIONE E SMALTIMENTO DEI RIFIUTI INDUSTRIALI'},
        {label: 'Sampling and monitoring', value: 'Campionamento e monitoraggio'},
        {label: 'Environmental remediation', value: 'BONIFICHE AMBIENTALI'},
        {label: 'Water treatment', value: 'TRATTAMENTO ACQUE PRIMARIE E REFLUE'},
        {label: 'Other environmental services', value: 'Altri servizi ambientali'},
      ]);
      break;

    case 'CONSULENZA ENERGETICA':
      return Promise.resolve([
        {label: 'Road To Zero', value: 'Net zero'},
        {label: 'Energy diagnosis and consultancy', value: 'Diagnosi e consulenze energetiche'},
        {label: 'Environmental consulting', value: 'CONSULENZE IN AMBITO GESTIONE AMBIENTALE'},
      ]);
      break;

    default:
      return Promise.resolve([]);
      break;
  }
};

export const servizio = {
  component: componentTypes.SELECT,
  name: 'scegli_il_servizio___tendina',
  label: 'Service',
  placeholder: "Choose a service",
  isRequired: true,
  FormFieldGridProps: { lg: 6 },
  TextFieldProps: { variant: "standard", InputLabelProps: {...labelProps} },
  simpleValue: true,
  ...defaultInputProps,
  isOptionEqualToValue: (option, value) => option.value === value,
  onInputChange: () => null,
  noOptionsMessage: 'Choose an area of interest',
  resolveProps: (_props, _field, { getState }) => {
    const areaDiInteressePrincipaleValue = getState().values.tipologia_richiesta__c;
    
    return {
      loadOptionsChangeCounter: filteredOptionsMapper(areaDiInteressePrincipaleValue),
      loadOptions: () => filteredServizioOptions(areaDiInteressePrincipaleValue)
    };
  },
};

export const luogoInteresse = {
  component: componentTypes.SELECT,
  name: 'luogo_di_interesse',
  label: 'Place of interest',
  placeholder: "Choose a place of intervention",
  isRequired: true,
  FormFieldGridProps: { lg: 6 },
  TextFieldProps: { variant: "standard", InputLabelProps: {...labelProps} },
  simpleValue: true,
  ...defaultInputProps,
  isOptionEqualToValue: (option, value) => option.value === value,
  options: [
    { label: "Abruzzo", value: "Abruzzo" },
    { label: "Basilicata", value: "Basilicata" },
    { label: "Calabria", value: "Calabria" },
    { label: "Campania", value: "Campania" },
    { label: "Emilia-Romagna", value: "Emilia-Romagna" },
    { label: "Friuli-Venezia Giulia", value: "Friuli-Venezia Giulia" },
    { label: "Lazio", value: "Lazio" },
    { label: "Liguria", value: "Liguria" },
    { label: "Lombardia", value: "Lombardia" },
    { label: "Marche", value: "Marche" },
    { label: "Molise", value: "Molise" },
    { label: "Piemonte", value: "Piemonte" },
    { label: "Puglia", value: "Puglia" },
    { label: "Sardegna", value: "Sardegna" },
    { label: "Sicilia", value: "Sicilia" },
    { label: "Toscana", value: "Toscana" },
    { label: "Trentino-Alto Adige", value: "Trentino-Alto Adige" },
    { label: "Umbria", value: "Umbria" },
    { label: "Valle d'Aosta", value: "Valle d'Aosta" },
    { label: "Veneto", value: "Veneto" },
    { label: "Whole of Italy", value: "Whole of Italy" },
  ],
  validate: [{ type: validatorTypes.REQUIRED, message: validationMessages.required }]
}

export const motivoContatto = {
  component: componentTypes.TEXT_FIELD,
  name: 'note_richiesta__c',
  label: "Tell us why you're contacting us",
  placeholder: "I need...",
  isRequired: true,
  FormFieldGridProps: { lg: 12 },
  multiline: true,
  InputLabelProps: {...labelProps},
  ...defaultInputProps,
  validate: [{ type: validatorTypes.REQUIRED, message: validationMessages.required }]
}

// Second step
export const name = {
  component: componentTypes.TEXT_FIELD,
  name: 'firstname',
  label: 'Name',
  placeholder: "Enter your name",
  isRequired: true,
  FormFieldGridProps: { lg: 6 },
  InputLabelProps: {...labelProps},
  ...defaultInputProps,
  validate: [
    { type: validatorTypes.REQUIRED, message: validationMessages.required },
    { type: validatorTypes.PATTERN, pattern: /^[A-Z ]+$/i, message: validationMessages.standard }
  ],
}

export const surname = {
  component: componentTypes.TEXT_FIELD,
  name: 'lastname',
  label: 'Surname',
  placeholder: "Enter your surname",
  isRequired: true,
  FormFieldGridProps: { lg: 6 },
  InputLabelProps: {...labelProps},
  ...defaultInputProps,
  validate: [
    { type: validatorTypes.REQUIRED, message: validationMessages.required },
    { type: validatorTypes.PATTERN, pattern: /^[A-Z ]+$/i, message: validationMessages.standard }
  ]
}

export const email = {
  component: componentTypes.TEXT_FIELD,
  name: 'email',
  label: 'Email',
  placeholder: "Enter your email: eg. name@xyz.com",
  isRequired: true,
  FormFieldGridProps: { lg: 6 },
  InputLabelProps: {...labelProps},
  ...defaultInputProps,
  validate: [
    { type: validatorTypes.REQUIRED, message: validationMessages.required },
    { type: validatorTypes.PATTERN, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: validationMessages.standard }
  ]
}

export const phone = {
  component: componentTypes.TEXT_FIELD,
  name: 'phone',
  label: 'Phone number',
  placeholder: "Enter your phone number: eg. +39 0123456789",
  isRequired: true,
  FormFieldGridProps: { lg: 6 },
  InputLabelProps: {...labelProps},
  ...defaultInputProps,
  validate: [
    { type: validatorTypes.REQUIRED, message: validationMessages.required },
    { type: validatorTypes.PATTERN, pattern: /^[\d|\+|\(]+[\)|\d|\s|-]*[\d]$/, message: validationMessages.standard },
    { type: validatorTypes.MIN_LENGTH, threshold: 8, message: validationMessages.phone.min },
    { type: validatorTypes.MAX_LENGTH, threshold: 15, message: validationMessages.phone.max },
  ]
}

export const mobilePhone = {
  component: componentTypes.TEXT_FIELD,
  name: 'mobilephone',
  label: 'Mobile Phone number',
  placeholder: "Enter you mobile phone number: eg. +39 0123456789",
  isRequired: true,
  FormFieldGridProps: { lg: 6 },
  InputLabelProps: {...labelProps},
  ...defaultInputProps,
  validate: [
    { type: validatorTypes.REQUIRED, message: validationMessages.required },
    { type: validatorTypes.PATTERN, pattern: /^[\d|\+|\(]+[\)|\d|\s|-]*[\d]$/, message: validationMessages.standard },
    { type: validatorTypes.MIN_LENGTH, threshold: 8, message: validationMessages.phone.min },
    { type: validatorTypes.MAX_LENGTH, threshold: 15, message: validationMessages.phone.max },
  ]
}

export const role = {
  component: componentTypes.SELECT,
  name: 'ruolo',
  label: 'Role',
  placeholder: "Enter your role",
  isRequired: true,
  simpleValue: true,
  isReadOnly: false,
  noValueUpdates: true,
  freeSolo: true,
  closeMenuOnSelect: false,
  noOptionsText: 'Nessun risultato',
  FormFieldGridProps: { lg: 6 },
  TextFieldProps: { variant: "standard", InputLabelProps: {...labelProps},  },
  inputProps: {...defaultInputProps, readOnly: false },
  isOptionEqualToValue: (option, value) => option.value === value,
  options: [
    { label: "President", value: 'Presidente' },
    { label: "CEO", value: 'Amministratore delegato' },
    { label: "General Manager", value: 'Direttore generale' },
    { label: "CFO", value: 'Direttore finanziario' },
    { label: "Executive", value: 'Dirigente' },
    { label: "Function Manager", value: 'Responsabile di funzione' },
    { label: "Factory Manager", value: 'Responsabile di stabilimento' },
    { label: "Energy Manager", value: 'Energy Manager' },
    { label: "Other", value: 'Altro' }
  ],
  validate: [{ type: validatorTypes.REQUIRED, message: validationMessages.required }]
}

export const rolePA = {
  component: componentTypes.SELECT,
  name: 'ruolo___pa',
  label: 'Role',
  placeholder: "Enter your role",
  isRequired: true,
  simpleValue: true,
  isReadOnly: false,
  FormFieldGridProps: { lg: 6 },
  TextFieldProps: { variant: "standard", InputLabelProps: {...labelProps},  },
  inputProps: {...defaultInputProps, readOnly: false, },
  isOptionEqualToValue: (option, value) => option.value === value,
  options: [
    { label: "President", value: 'Presidente' },
    { label: "Superintendent", value: 'Provveditore' },
    { label: "General Manager", value: 'Direttore generale' },
    { label: "Councillor", value: 'Assessore' },
    { label: "Mayor", value: 'Sindaco' },
    { label: "Public Executive", value: 'Dirigente pubblico' },
    { label: "Site Manager", value: 'Direttore di sito' },
    { label: "Public Administrator", value: 'Funzionario' },
    { label: "Function Manager", value: 'Responsabile di funzione' },
    { label: "Other", value: 'Altro' }
  ],
  validate: [{ type: validatorTypes.REQUIRED, message: validationMessages.required }]
}

export const jobTitle = {
  component: componentTypes.SELECT,
  name: 'job_title___tendina',
  label: 'Job Title (optional)' ,
  placeholder: "Enter job title",
  FormFieldGridProps: { lg: 6 },
  TextFieldProps: {  variant: "standard", InputLabelProps: {...labelProps} },
  inputProps: { ...defaultInputProps,  },
  simpleValue: true,
  isClearable: true,
  isSearchable: true,
  noOptionsText: 'Nessun risultato',
  options: jobsValues.filter(job => job.category != 'Pubblico'),
  isOptionEqualToValue: (option, value) => option.value === value,
  isRequired: false,
  // validate: [{ type: validatorTypes.REQUIRED, message: validationMessages.required }]
}
export const jobTitlePa = {
  component: componentTypes.SELECT,
  name: 'job_title___tendina',
  label: 'Job Title (optional)',
  placeholder: "Enter job title",
  FormFieldGridProps: { lg: 6 },
  TextFieldProps: {  variant: "standard", InputLabelProps: {...labelProps} },
  inputProps: { ...defaultInputProps,  },
  simpleValue: true,
  isClearable: true,
  isSearchable: true,
  noOptionsText: 'Nessun risultato',
  options: jobsValues.filter(job => job.category != 'Privato'),
  isOptionEqualToValue: (option, value) => option.value === value,
  isRequired: false,
  // validate: [{ type: validatorTypes.REQUIRED, message: validationMessages.required }]
}

// Third step
export const settore = {
  component: componentTypes.SELECT,
  name: 'settore___azienda_solo_per__form_b2b',
  label: 'Sector',
  placeholder: "Choose a sector",
  isRequired: true,
  FormFieldGridProps: { lg: 6 },
  TextFieldProps: { variant: "standard", InputLabelProps: {...labelProps} },
  simpleValue: true,
  ...defaultInputProps,
  isOptionEqualToValue: (option, value) => option.value === value,
  options: [
    { label: "Hospitality and foodservice", value: 'Alberghiero e Ristorazione' },
    { label: "Food", value: 'Alimentare' },
    { label: "Public administration", value: 'Amministrazione pubblica' },
    { label: "Insurance", value: 'Assicurativo' },
    { label: "Automotive", value: 'Automotive' },
    { label: "Banking", value: 'Bancario' },
    { label: "Chemical and pharmaceutical", value: 'Chimico e Farmaceutico' },
    { label: "Commerce", value: 'Commercio' },
    { label: "Construction", value: 'Edile' },
    { label: "Electronics", value: 'Elettronico' },
    { label: "Energy", value: 'Energetico' },
    { label: "Funds and asset management", value: 'Fondi e SGR' },
    { label: "Rubber and plastic", value: 'Gomma e Plastica' },
    { label: "Education", value: 'Istruzione' },
    { label: "Machinery", value: 'Macchinari' },
    { label: "Metalworking", value: 'Metallurgico' },
    { label: "Public organizations", value: 'Organizzazioni pubbliche' },
    { label: "Healthcare", value: 'Sanità' },
    { label: "Printing and paper", value: 'Stampa e Cartario' },
    { label: "Telecommunications", value: 'Telecomunicazioni' },
    { label: "Textiles", value: 'Tessile' },
    { label: "Transport and logistics", value: 'Trasporti e Logistica' },
    { label: "Glass and ceramics", value: 'Vetro e Ceramica' },
    { label: "Other - Industry", value: 'Altro - Industria' },
    { label: "Other - Service", value: 'Altro - Terziario' }
  ],
  validate: [{ type: validatorTypes.REQUIRED, message: validationMessages.required },]
}

export const settorePA = {
  component: componentTypes.SELECT,
  name: 'settore___pa_solo_per_form_b2g',
  label: 'Sector',
  placeholder: "Choose a sector",
  isRequired: true,
  FormFieldGridProps: { lg: 6 },
  TextFieldProps: { variant: "standard", InputLabelProps: {...labelProps} },
  simpleValue: true,
  ...defaultInputProps,
  isOptionEqualToValue: (option, value) => option.value === value,
  options: [
    { label: "Public administration", value: 'Amministrazione pubblica' },
    { label: "Education", value: 'Istruzione' },
    { label: "Healthcare", value: 'Sanità' },
    { label: "Public organizations", value: 'Organizzazioni pubbliche' }
  ],
  validate: [{ type: validatorTypes.REQUIRED, message: validationMessages.required },]
}

export const societa = {
  component: componentTypes.TEXT_FIELD,
  name: 'company',
  label: 'Company',
  placeholder: "Enter company name",
  isRequired: true,
  FormFieldGridProps: { lg: 6 },
  InputLabelProps: {...labelProps},
  ...defaultInputProps,
  validate: [{ type: validatorTypes.REQUIRED, message: validationMessages.required },]
}

export const entePubblico = {
  component: componentTypes.TEXT_FIELD,
  name: 'company',
  label: 'Public authority',
  placeholder: "Enter the name of the government agency'",
  isRequired: true,
  FormFieldGridProps: { lg: 6 },
  InputLabelProps: {...labelProps},
  ...defaultInputProps,
  validate: [{ type: validatorTypes.REQUIRED, message: validationMessages.required }]
}

export const piva = {
  component: componentTypes.TEXT_FIELD,
  name: 'partita_iva',
  label: 'VAT number',
  placeholder: "Enter 11-digit VAT number",
  isRequired: true,
  FormFieldGridProps: { lg: 6 },
  InputLabelProps: {...labelProps},
  ...defaultInputProps,
  validate: [
    { type: validatorTypes.REQUIRED, message: validationMessages.required },
    { type: validatorTypes.EXACT_LENGTH, threshold: 11, message: validationMessages.piva.length}
  ]
}

export const ipa = {
  component: componentTypes.TEXT_FIELD,
  name: 'codice_ipa',
  label: 'IPA Code (optional)',
  placeholder: "Enter IPA code",
  isRequired: false,
  FormFieldGridProps: { lg: 6 },
  InputLabelProps: {...labelProps},
  ...defaultInputProps,
}

export const cap = {
  component: componentTypes.TEXT_FIELD,
  name: 'zip',
  label: 'Postal Code',
  placeholder: "Enter Postal Code",
  isRequired: true,
  FormFieldGridProps: { lg: 6 },
  InputLabelProps: {...labelProps},
  ...defaultInputProps,
  validate: [
    { type: validatorTypes.PATTERN, pattern: /^[0-9]{5}([- /]?[0-9]{4})?$/, message: validationMessages.cap },
  ]
}

// privacy
export const marketing1 = {
  component: componentTypes.SWITCH,
  name: 'consenso_1_x_form',
  label: 'I agree to the processing of my data by Edison NEXT for promotional, commercial and marketing purposes, as described in art. 4, para. II, letter A.',
  variant: "standard",
  initialValue: false,
  isRequired: false,
  FormFieldGridProps: { lg: 12 },
  ...defaultInputProps,
}

export const marketing2 = {
  component: componentTypes.SWITCH,
  name: 'consenso_2_x_form',
  label: 'I agree to the transmission of my data to Edison Group businesses for their promotional, commercial and marketing purposes, as described in art. 4, para. II, letter B.',
  variant: "standard",
  initialValue: false,
  isRequired: false,
  FormFieldGridProps: { lg: 12 },
  ...defaultInputProps,
}

export const marketing3 = {
  component: componentTypes.SWITCH,
  name: 'consenso_3_x_form',
  label: 'I agree to the transmission of my data to Edison NEXT’s commercial partners for their promotional, commercial and marketing purposes, as described in art. 4, para. II, letter C.',
  variant: "standard",
  initialValue: false,
  isRequired: false,
  FormFieldGridProps: { lg: 12 },
  ...defaultInputProps,
}

export const privacy = {
  component: 'plain-text',
  name: 'privacy-policy',
  label: 'By clicking on the "Send" button, I confirm that I have read the <a href=\"/cp/Info_privacy_rev12_2022_contattaci_ENG.pdf\" target=\"_blank\">privacy information.</a>.',
  gridProps: { lg: 12, mt: 50, mb:16 },
  inputProps: {...defaultInputProps, textAlign: 'center', variant: "description"},
}

export const hiddenField = {
  component: componentTypes.CHECKBOX,
  value: true,
  initialValue: true,
  name: 'consenso_4_x_form',
  hideField: true,
}
